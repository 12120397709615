<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Motivos</h4>
            <div class="small text-muted">Motivos de un pedido</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Motivo">
              <i class="fa fa-plus"></i> Agregar
            </b-button>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">  
        <b-row>
          <b-col>
            <b-table class="mb-0"
                    ref="table"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"                    
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    :busy="table.isBusy"     
                    v-if="table.items.length || table.isBusy">                       
                
                <template v-slot:cell(id)="data">
                  <b>#{{data.item.id}}</b>
                </template>

                <template v-slot:cell(code)="data">
                  <b>{{data.item.code}}</b>
                </template>
                
                <template v-slot:cell(reference)="data">
                  <b>{{data.item.reference}}</b>
                </template>

                <template v-slot:cell(name)="data">
                  {{data.item.name}}
                </template>
               
                <template v-slot:cell(show_website)="data">
                  <b-badge variant="dark" v-if="data.item.show_website">SI</b-badge>                  
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                    
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>            
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="filter()" />
            </nav>             
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>            
          <b-col md="6">
            <b-form-group label="Código">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.code"
                            required
                            placeholder="Ingresar un código">
              </b-form-input>
            </b-form-group>
          </b-col>         
          <b-col md="6">
            <b-form-group label="Referencia">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.reference"
                            required
                            placeholder="Ingresar una referencia">
              </b-form-input>
            </b-form-group>
          </b-col>                                        
          <b-col md="12">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>
          </b-col>           
          <b-col md="12" class="mt-2 mb-2">
            <b-form-checkbox v-model="crud.form.show_website" 
                              switch 
                              size="sm" 
                              class="pull-right">
              Muestra en sitio web
            </b-form-checkbox> 
          </b-col>                        
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>    
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Param from '@/config/parameters'
  import FindObject from '@/components/inc/find/findObject'  

  export default {
    components: {
      FindObject,      
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.PEDIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudOrdersReason',
          elements: {}
        },         
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class: 'align-middle'},
            {key: 'code', label: 'Código', class: 'align-middle'},      
            {key: 'reference', label: 'Referencia', class: 'align-middle'},      
            {key: 'name', label: 'Nombre', class: 'align-middle'},                               
            {key: 'show_website', label: 'Muestra en Sitio Web', class: 'text-center align-middle'},                               
            {key: 'f_action', label:'', class: 'text-center'},
          ],          
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,                
        },
        crud: {
          form: {
            id: 0,
            code: '',
            reference: '',
            name: '',       
            show_website: false,       
          },          
        },
        modal: {
          form: {
            active: false,
            title: ''
          },       
        },
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.filter()
    },
    methods: {
      // crud
      filter() {
        this.table.isBusy = true

        var result = serviceAPI.filtrarMotivos(this.filters, this.table.currentPage)

        result.then((response) => {
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data

          if(this.$refs.table) {                        
            this.$refs.table.$forceUpdate()
          }  

          this.table.isBusy = false          
        })
        .catch(error => {
          this.table.isBusy = false
          //this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.code = '' 
        this.crud.form.reference = '' 
        this.crud.form.name = '' 
        this.crud.form.show_website = false

        this.modal.form.title = "Nuevo Motivo"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.code = item.code
        this.crud.form.reference = item.reference       
        this.crud.form.name = item.name    
        this.crud.form.show_website = item.show_website 
        
        this.modal.form.title = "Editar Motivo"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el motivo (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Motivo',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarMotivos(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filter()
              this.$awn.success("Motivo Eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();

        if (this.crud.form.id) {
          var result = serviceAPI.editarMotivos(this.crud.form);
        } else {
          var result = serviceAPI.agregarMotivos(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.filter()
          this.$awn.success("Cambios guardado con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },                       
    }    
  }
</script>
<style>

</style>